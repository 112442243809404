@use './variables';
/* TOOGLE SWICH */
.onoffswitch {
  position: relative;
  width: 64px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.onoffswitch-checkbox {
  display: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid variables.$navy;
  border-radius: 2px;
}

.onoffswitch-inner {
  width: 200%;
  margin-left: -100%;
  -moz-transition: margin 0.3s ease-in 0s;
  -webkit-transition: margin 0.3s ease-in 0s;
  -o-transition: margin 0.3s ease-in 0s;
  transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
  float: left;
  width: 50%;
  height: 20px;
  padding: 0;
  line-height: 20px;
  font-size: 12px;
  color: white;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.onoffswitch-inner:before {
  content: 'ON';
  padding-left: 10px;
  background-color: variables.$navy;
  color: #ffffff;
}

.onoffswitch-inner:after {
  content: 'OFF';
  padding-right: 10px;
  background-color: #ffffff;
  color: #999999;
  text-align: right;
}

.onoffswitch-switch {
  width: 20px;
  margin: 0;
  background: #ffffff;
  border: 2px solid variables.$navy;
  border-radius: 2px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 44px;
  -moz-transition: all 0.3s ease-in 0s;
  -webkit-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0;
}

.onoffswitch-checkbox:disabled + .onoffswitch-label .onoffswitch-inner:before {
  background-color: #919191;
}

.onoffswitch-checkbox:disabled + .onoffswitch-label,
.onoffswitch-checkbox:disabled + .onoffswitch-label .onoffswitch-switch {
  border-color: #919191;
}

/* CHOSEN PLUGIN */

.chosen-container-single .chosen-single {
  background: #ffffff;
  box-shadow: none;
  -moz-box-sizing: border-box;
  border-radius: 2px;
  cursor: text;
  height: auto !important;
  margin: 0;
  min-height: 30px;
  overflow: hidden;
  padding: 4px 12px;
  position: relative;
  width: 100%;
}

.chosen-container-multi .chosen-choices li.search-choice {
  background: #f1f1f1;
  border: 1px solid #e5e6e7;
  border-radius: 2px;
  box-shadow: none;
  color: #333333;
  cursor: default;
  line-height: 13px;
  margin: 3px 0 3px 5px;
  padding: 3px 20px 3px 5px;
  position: relative;
}

/* Tags Input Plugin */

.bootstrap-tagsinput {
  border: 1px solid #e5e6e7;
  box-shadow: none;
}

/* PAGINATIN */

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  //background-color: #f4f4f4;
  border-color: #dddddd;
  //color: inherit;
  cursor: default;
  z-index: 2;
}

.pagination > li > a,
.pagination > li > span {
  background-color: #ffffff;
  border: 1px solid #dddddd;
  color: inherit;
  float: left;
  line-height: 1.42857;
  margin-left: -1px;
  padding: 4px 10px;
  position: relative;
  text-decoration: none;
}

.page-item.active .page-link {
  background-color: variables.$navy;
  border-color: variables.$navy;
}

.page-link:focus {
  box-shadow: none;
}

.page-link:hover {
  color: variables.$text-color;
}

.pagination .footable-page.active a {
  background-color: variables.$navy;
  border-color: variables.$navy;
  color: white;
}

/* TOOLTIPS */

.tooltip-inner {
  background-color: variables.$nav-bg;
}

.tooltip.top .tooltip-arrow {
  border-top-color: variables.$nav-bg;
}

.tooltip.right .tooltip-arrow {
  border-right-color: variables.$nav-bg;
}

.tooltip.bottom .tooltip-arrow {
  border-bottom-color: variables.$nav-bg;
}

.tooltip.left .tooltip-arrow {
  border-left-color: variables.$nav-bg;
}

/* EASY PIE CHART*/

.easypiechart {
  position: relative;
  text-align: center;
}

.easypiechart .h2 {
  margin-left: 10px;
  margin-top: 10px;
  display: inline-block;
}

.easypiechart canvas {
  top: 0;
  left: 0;
}

.easypiechart .easypie-text {
  line-height: 1;
  position: absolute;
  top: 33px;
  width: 100%;
  z-index: 1;
}

.easypiechart img {
  margin-top: -4px;
}

.jqstooltip {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}


/* LIST GROUP */

a.list-group-item.active,
a.list-group-item.active:hover,
a.list-group-item.active:focus {
  background-color: variables.$navy;
  border-color: variables.$navy;
  color: #ffffff;
  z-index: 2;
}

.list-group-item-heading {
  margin-top: 10px;
}

.list-group-item-text {
  margin: 0 0 10px;
  color: inherit;
  font-size: 12px;
  line-height: inherit;
}

.no-padding .list-group-item {
  border-left: none;
  border-right: none;
  border-bottom: none;
}

.no-padding .list-group-item:first-child {
  border-left: none;
  border-right: none;
  border-bottom: none;
  border-top: none;
}

.no-padding .list-group {
  margin-bottom: 0;
}

.list-group-item {
  background-color: inherit;
  border: 1px solid variables.$border-color;
  display: block;
  margin-bottom: -1px;
  padding: 10px 15px;
  position: relative;
}

.elements-list .list-group-item {
  border-left: none;
  border-right: none;
  padding: 0;
}

.elements-list .list-group-item:first-child {
  border-left: none;
  border-right: none;
  border-top: none !important;
}

.elements-list .list-group {
  margin-bottom: 0;
}

.elements-list a {
  color: inherit;
}

.elements-list .list-group-item a.active,
.elements-list .list-group-item a:hover {
  background: #f3f3f4;
  color: inherit;
  border-color: #e7eaec;
  border-radius: 0;
}

.elements-list li.active {
  transition: none;
}

.elements-list .nav-link {
  padding: 15px 25px;
}

.element-detail-box {
  padding: 25px;
}

.fh-150 {
  height: 150px;
}

.fh-200 {
  height: 200px;
}

.h-150 {
  min-height: 150px;
}

.h-200 {
  min-height: 200px;
}

.h-300 {
  min-height: 300px;
}

.w-150 {
  min-width: 150px;
}

.w-200 {
  min-width: 200px;
}

.w-300 {
  min-width: 300px;
}

.legendLabel {
  padding-left: 5px;
}

.stat-list li:first-child {
  margin-top: 0;
}

.stat-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.stat-percent {
  float: right;
}

.stat-list li {
  margin-top: 15px;
  position: relative;
}

/* DATATABLES */

table.dataTable thead .sorting,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  background: transparent;
}

.dataTables_wrapper {
  padding-bottom: 30px;
}

.dataTables_length {
  float: left;
}

.dataTables_filter label {
  margin-right: 5px;
}

.html5buttons {
  float: right;
}

.html5buttons a {
  border: 1px solid #e7eaec;
  background: #fff;
  color: #676a6c;
  box-shadow: none;
  padding: 6px 8px;
  font-size: 12px;

  &:hover,
  &:focus:active {
    background-color: #eee;
    color: inherit;
    border-color: #d2d2d2;
  }
}

div.dt-button-info {
  z-index: 100;
}

@media (max-width: 768px) {
  .html5buttons {
    float: none;
    margin-top: 10px;
  }

  .dataTables_length {
    float: none;
  }
}

/* CIRCLE */

.img-circle {
  border-radius: 50%;
}

.btn-circle {
  width: 30px;
  height: 30px;
  padding: 6px 0;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.428571429;
}

.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding: 10px 16px;
  border-radius: 25px;
  font-size: 18px;
  line-height: 1.33;
}

.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 24px;
  line-height: 1.33;
}

.show-grid [class^='col-'] {
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid #ddd;
  background-color: #eee !important;
}

.show-grid {
  margin: 15px 0;
}

/* ANIMATION */

.css-animation-box h1 {
  font-size: 44px;
}

.animation-efect-links a {
  padding: 4px 6px;
  font-size: 12px;
}

#animation_box {
  background-color: #f9f8f8;
  border-radius: 16px;
  width: 80%;
  margin: 0 auto;
  padding-top: 80px;
}

.animation-text-box {
  position: absolute;
  margin-top: 40px;
  left: 50%;
  margin-left: -100px;
  width: 200px;
}

.animation-text-info {
  position: absolute;
  margin-top: -60px;
  left: 50%;
  margin-left: -100px;
  width: 200px;
  font-size: 10px;
}

.animation-text-box h2 {
  font-size: 54px;
  font-weight: 600;
  margin-bottom: 5px;
}

.animation-text-box p {
  font-size: 12px;
  text-transform: uppercase;
}

/* PEACE */

.pace {
  -webkit-pointer-events: none;
  pointer-events: none;

  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.pace-inactive {
  display: none;
}

.pace .pace-progress {
  background: white;
  position: fixed;
  z-index: 2040;
  top: 0;
  right: 100%;
  width: 100%;
  height: 2px;
}

.pace-inactive {
  display: none;
}

/* WIDGETS */

.widget {
  border-radius: 5px;
  padding: 15px 20px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.widget.style1 h2 {
  font-size: 30px;
}

.widget h2,
.widget h3 {
  margin-top: 5px;
  margin-bottom: 0;
}

.widget-text-box {
  padding: 20px;
  border: 1px solid variables.$border-color;
  background: #ffffff;
}

.widget-head-color-box {
  border-radius: 5px 5px 0 0;
  margin-top: 10px;
}

.widget .flot-chart {
  height: 100px;
}

.vertical-align div {
  display: inline-block;
  vertical-align: middle;
}

.vertical-align h2,
.vertical-align h3 {
  margin: 0;
}

.todo-list {
  list-style: none outside none;
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.todo-list.small-list {
  font-size: 12px;
}

.todo-list.small-list > li {
  background: variables.$gray;
  border-left: none;
  border-right: none;
  border-radius: 4px;
  color: inherit;
  margin-bottom: 2px;
  padding: 6px 6px 6px 12px;
}

.todo-list.small-list .btn-sm,
.todo-list.small-list .btn-group-xs > .btn {
  border-radius: 5px;
  font-size: 10px;
  line-height: 1.5;
  padding: 1px 2px 1px 5px;
}

.todo-list > li {
  background: variables.$gray;
  border-left: 6px solid variables.$border-color;
  border-right: 6px solid variables.$border-color;
  border-radius: 4px;
  color: inherit;
  margin-bottom: 2px;
  padding: 10px;
}

.todo-list .handle {
  cursor: move;
  display: inline-block;
  font-size: 16px;
  margin: 0 5px;
}

.todo-list > li .label {
  font-size: 9px;
  margin-left: 10px;
}

.check-link {
  font-size: 16px;
}

.todo-completed {
  text-decoration: line-through;
}

.geo-statistic h1 {
  font-size: 36px;
  margin-bottom: 0;
}

.glyphicon.fa {
  font-family: 'FontAwesome';
}

/* INPUTS */
.inline {
  display: inline-block !important;
}

.input-s-sm {
  width: 120px;
}

.input-s {
  width: 200px;
}

.form-control {
  font-size: 0.9rem;
}

select.form-control:not([size]):not([multiple]) {
  height: 2.05rem;
}

.input-sm,
.form-control-sm {
  height: 31px;
}

.input-s-lg {
  width: 250px;
}

.i-checks {
  padding-left: 0;
}

.form-control,
.single-line {
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #e5e6e7;
  border-radius: 1px;
  color: inherit;
  display: block;
  padding: 6px 12px;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  width: 100%;
}

.form-control:focus,
.single-line:focus {
  border-color: variables.$navy;
}

.has-success .form-control,
.has-success .form-control:focus {
  border-color: variables.$navy;
}

.has-warning .form-control,
.has-warning .form-control:focus {
  border-color: variables.$yellow;
}

.has-error .form-control,
.has-error .form-control:focus {
  border-color: variables.$red;
}

.has-success .control-label {
  color: variables.$navy;
}

.has-warning .control-label {
  color: variables.$yellow;
}

.has-error .control-label {
  color: variables.$red;
}

.input-group-addon {
  background-color: #fff;
  border: 1px solid #e5e6e7;
  border-radius: 1px;
  color: inherit;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  padding: 9px 12px 4px 12px;
  text-align: center;
}

.spinner-buttons.input-group-btn .btn-sm {
  line-height: 1.13;
}

.spinner-buttons.input-group-btn {
  width: 20%;
}

.noUi-connect {
  background: none repeat scroll 0 0 variables.$navy;
  box-shadow: none;
}

.slider_red .noUi-connect {
  background: none repeat scroll 0 0 variables.$red;
  box-shadow: none;
}

/* UI Sortable */

.ui-sortable .ibox-title {
  cursor: move;
}

.ui-sortable-placeholder {
  border: 1px dashed #cecece !important;
  visibility: visible !important;
  background: #e7eaec;
}

.ibox.ui-sortable-placeholder {
  margin: 0 0 23px !important;
}

/* SWITCHES */
.onoffswitch {
  position: relative;
  width: 54px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.onoffswitch-checkbox {
  display: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 2px solid #1ab394;
  border-radius: 3px;
}

.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  -moz-transition: margin 0.3s ease-in 0s;
  -webkit-transition: margin 0.3s ease-in 0s;
  -o-transition: margin 0.3s ease-in 0s;
  transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 16px;
  padding: 0;
  line-height: 16px;
  font-size: 10px;
  color: white;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.onoffswitch-inner:before {
  content: 'ON';
  padding-left: 7px;
  background-color: #1ab394;
  color: #ffffff;
}

.onoffswitch-inner:after {
  content: 'OFF';
  padding-right: 7px;
  background-color: #ffffff;
  color: #919191;
  text-align: right;
}

.onoffswitch-switch {
  display: block;
  width: 18px;
  margin: 0;
  background: #ffffff;
  border: 2px solid #1ab394;
  border-radius: 3px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 36px;
  -moz-transition: all 0.3s ease-in 0s;
  -webkit-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0;
}

/* jqGrid */

.ui-jqgrid {
  -moz-box-sizing: content-box;
}

.ui-jqgrid-btable {
  border-collapse: separate;
}

.ui-jqgrid-htable {
  border-collapse: separate;
}

.ui-jqgrid-titlebar {
  height: 40px;
  line-height: 15px;
  color: #676a6c;
  background-color: #f9f9f9;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
}

.ui-jqgrid .ui-jqgrid-title {
  float: left;
  margin: 1.1em 1em 0.2em;
}

.ui-jqgrid .ui-jqgrid-titlebar {
  position: relative;
  border-left: 0 solid;
  border-right: 0 solid;
  border-top: 0 solid;
}

.ui-widget-header {
  background: none;
  background-image: none;
  background-color: #f5f5f6;
  text-transform: uppercase;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.ui-jqgrid tr.ui-row-ltr td {
  border-right-color: inherit;
  border-right-style: solid;
  border-right-width: 1px;
  text-align: left;
  border-color: #dddddd;
  background-color: inherit;
}

.ui-search-toolbar input[type='text'] {
  font-size: 12px;
  height: 15px;
  border: 1px solid #cccccc;
  border-radius: 0;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  background: #f9f9f9;
  border: 1px solid #dddddd;
  line-height: 15px;
  font-weight: bold;
  color: #676a6c;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
}

.ui-widget-content {
  box-sizing: content-box;
}

.ui-icon-triangle-1-n {
  background-position: 1px -16px;
}

.ui-jqgrid tr.ui-search-toolbar th {
  border-top-width: 0 !important;
  border-top-color: inherit !important;
  border-top-style: ridge !important;
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
  background: #f5f5f5;
  border-collapse: separate;
}

.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
  background: #f2fbff;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
  border: 1px solid #dddddd;
  background: #ffffff;
  font-weight: normal;
  color: #212121;
}

.ui-jqgrid .ui-pg-input {
  font-size: inherit;
  width: 50px;
  border: 1px solid #cccccc;
  height: 15px;
}

.ui-jqgrid .ui-pg-selbox {
  display: block;
  font-size: 1em;
  height: 25px;
  line-height: 18px;
  margin: 0;
  width: auto;
}

.ui-jqgrid .ui-pager-control {
  position: relative;
}

.ui-jqgrid .ui-jqgrid-pager {
  height: 32px;
  position: relative;
}

.ui-pg-table .navtable .ui-corner-all {
  border-radius: 0;
}

.ui-jqgrid .ui-pg-button:hover {
  padding: 1px;
  border: 0;
}

.ui-jqgrid .loading {
  position: absolute;
  top: 45%;
  left: 45%;
  width: auto;
  height: auto;
  z-index: 101;
  padding: 6px;
  margin: 5px;
  text-align: center;
  font-weight: bold;
  display: none;
  border-width: 2px !important;
  font-size: 11px;
}

.ui-jqgrid .form-control {
  height: 10px;
  width: auto;
  display: inline;
  padding: 10px 12px;
}

.ui-jqgrid-pager {
  height: 32px;
}

.ui-corner-all,
.ui-corner-top,
.ui-corner-left,
.ui-corner-tl {
  border-top-left-radius: 0;
}

.ui-corner-all,
.ui-corner-top,
.ui-corner-right,
.ui-corner-tr {
  border-top-right-radius: 0;
}

.ui-corner-all,
.ui-corner-bottom,
.ui-corner-left,
.ui-corner-bl {
  border-bottom-left-radius: 0;
}

.ui-corner-all,
.ui-corner-bottom,
.ui-corner-right,
.ui-corner-br {
  border-bottom-right-radius: 0;
}

.ui-widget-content {
  border: 1px solid #ddd;
}

.ui-jqgrid .ui-jqgrid-titlebar {
  padding: 0;
}

.ui-jqgrid .ui-jqgrid-titlebar {
  border-bottom: 1px solid #ddd;
}

.ui-jqgrid tr.jqgrow td {
  padding: 6px;
}

.ui-jqdialog .ui-jqdialog-titlebar {
  padding: 10px 10px;
}

.ui-jqdialog .ui-jqdialog-title {
  float: none !important;
}

.ui-jqdialog > .ui-resizable-se {
  position: absolute;
}

/* Nestable list */

.dd {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 13px;
  line-height: 20px;
}

.dd-list {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
}

.dd-list .dd-list {
  padding-left: 30px;
}

.dd-collapsed .dd-list {
  display: none;
}

.dd-item,
.dd-empty,
.dd-placeholder {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 20px;
  font-size: 13px;
  line-height: 20px;
}

.dd-handle {
  display: block;
  margin: 5px 0;
  padding: 5px 10px;
  color: #333;
  text-decoration: none;
  border: 1px solid #e7eaec;
  background: #f5f5f5;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.dd-handle span {
  font-weight: bold;
}

.dd-handle:hover {
  background: #f0f0f0;
  cursor: pointer;
  font-weight: bold;
}

.dd-item > button {
  display: block;
  position: relative;
  cursor: pointer;
  float: left;
  width: 25px;
  height: 20px;
  margin: 5px 0;
  padding: 0;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 0;
  background: transparent;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  font-weight: bold;
}

.dd-item > button:before {
  content: '+';
  display: block;
  position: absolute;
  width: 100%;
  text-align: center;
  text-indent: 0;
}

.dd-item > button[data-action='collapse']:before {
  content: '-';
}

#nestable2 .dd-item > button {
  font-family: FontAwesome;
  height: 34px;
  width: 33px;
  color: #c1c1c1;
}

#nestable2 .dd-item > button:before {
  content: '\f067';
}

#nestable2 .dd-item > button[data-action='collapse']:before {
  content: '\f068';
}

.dd-placeholder,
.dd-empty {
  margin: 5px 0;
  padding: 0;
  min-height: 30px;
  background: #f2fbff;
  border: 1px dashed #b6bcbf;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.dd-empty {
  border: 1px dashed #bbb;
  min-height: 100px;
  background-color: #e5e5e5;
  background-image: -webkit-linear-gradient(
      45deg,
      #fff 25%,
      transparent 25%,
      transparent 75%,
      #fff 75%,
      #fff
  ),
  -webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
  background-image: -moz-linear-gradient(
      45deg,
      #fff 25%,
      transparent 25%,
      transparent 75%,
      #fff 75%,
      #fff
  ),
  -moz-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
  background-image: linear-gradient(
      45deg,
      #fff 25%,
      transparent 25%,
      transparent 75%,
      #fff 75%,
      #fff
  ),
  linear-gradient(
      45deg,
      #fff 25%,
      transparent 25%,
      transparent 75%,
      #fff 75%,
      #fff
  );
  background-size: 60px 60px;
  background-position: 0 0, 30px 30px;
}

.dd-dragel {
  position: absolute;
  z-index: 9999;
  pointer-events: none;
}

.dd-dragel > .dd-item .dd-handle {
  margin-top: 0;
}

.dd-dragel .dd-handle {
  -webkit-box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
}

/**
* Nestable Extras
*/
.nestable-lists {
  display: block;
  clear: both;
  padding: 30px 0;
  width: 100%;
  border: 0;
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
}

#nestable-menu {
  padding: 0;
  margin: 10px 0 20px 0;
}

#nestable-output,
#nestable2-output {
  width: 100%;
  font-size: 0.75em;
  line-height: 1.333333em;
  font-family: open sans, lucida grande, lucida sans unicode, helvetica, arial,
  sans-serif;
  padding: 5px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

#nestable2 .dd-handle {
  color: inherit;
  border: 1px dashed #e7eaec;
  background: #f3f3f4;
  padding: 10px;
}

#nestable2 span.label {
  margin-right: 10px;
}

#nestable-output,
#nestable2-output {
  font-size: 12px;
  padding: 25px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

/* CodeMirror */
.CodeMirror {
  border: 1px solid #eee;
  height: auto;
}

.CodeMirror-scroll {
  overflow-y: hidden;
  overflow-x: auto;
}

/* Google Maps */
.google-map {
  height: 300px;
}

/* Validation */
label.error {
  color: #cc5965;
  display: inline-block;
  margin-left: 5px;
}

.form-control.error {
  border: 1px dotted #cc5965;
}

/* ngGrid */
.gridStyle {
  border: 1px solid rgb(212, 212, 212);
  width: 100%;
  height: 400px;
}

.gridStyle2 {
  border: 1px solid rgb(212, 212, 212);
  width: 500px;
  height: 300px;
}

.ngH eaderCell {
  border-right: none;
  border-bottom: 1px solid #e7eaec;
}

.ngCell {
  border-right: none;
}

.ngTopPanel {
  background: #f5f5f6;
}

.ngRow.even {
  background: #f9f9f9;
}

.ngRow.selected {
  background: #ebf2f1;
}

.ngRow {
  border-bottom: 1px solid #e7eaec;
}

.ngCell {
  background-color: transparent;
}

.ngHeaderCell {
  border-right: none;
}

/* Toastr custom style */

#toast-container > div {
  -moz-box-shadow: 0 0 3px #999;
  -webkit-box-shadow: 0 0 3px #999;
  box-shadow: 0 0 3px #999;
  opacity: 0.9;
  -ms-filter: alpha(Opacity=90);
  filter: alpha(opacity=90);
}

#toast-container > :hover {
  -moz-box-shadow: 0 0 4px #999;
  -webkit-box-shadow: 0 0 4px #999;
  box-shadow: 0 0 4px #999;
  opacity: 1;
  -ms-filter: alpha(Opacity=100);
  filter: alpha(opacity=100);
  cursor: pointer;
}

.toast {
  background-color: variables.$navy;
}

.toast-success {
  background-color: variables.$navy;
}

.toast-error {
  background-color: variables.$red;
}

.toast-info {
  background-color: variables.$lazur;
}

.toast-warning {
  background-color: variables.$yellow;
}

.toast-top-full-width {
  margin-top: 20px;
}

.toast-bottom-full-width {
  margin-bottom: 20px;
}

/* Notifie */
.cg-notify-message.inspinia-notify {
  background: #fff;
  padding: 0;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.2);
  border: none;
  margin-top: 30px;
  color: inherit;
}

.inspinia-notify.alert-warning {
  border-left: 6px solid variables.$yellow;
}

.inspinia-notify.alert-success {
  border-left: 6px solid variables.$blue;
}

.inspinia-notify.alert-danger {
  border-left: 6px solid variables.$red;
}

.inspinia-notify.alert-info {
  border-left: 6px solid variables.$navy;
}

/* Image cropper style */
.img-container,
.img-preview {
  overflow: hidden;
  text-align: center;
  width: 100%;
}

.img-preview-sm {
  height: 130px;
  width: 200px;
}

/* Forum styles  */
.forum-post-container .media {
  margin: 10px 10px 10px 10px;
  padding: 20px 10px 20px 10px;
  border-bottom: 1px solid #f1f1f1;
}

.forum-avatar {
  float: left;
  margin-right: 20px;
  text-align: center;
  width: 110px;
}

.forum-avatar .rounded-circle {
  height: 48px;
  width: 48px;
}

.author-info {
  color: #676a6c;
  font-size: 11px;
  margin-top: 5px;
  text-align: center;
}

.forum-post-info {
  padding: 9px 12px 6px 12px;
  background: #f9f9f9;
  border: 1px solid #f1f1f1;
}

.media-body > .media {
  background: #f9f9f9;
  border-radius: 3px;
  border: 1px solid #f1f1f1;
}

.forum-post-container .media-body .photos {
  margin: 10px 0;
}

.forum-photo {
  max-width: 140px;
  border-radius: 3px;
}

.media-body > .media .forum-avatar {
  width: 70px;
  margin-right: 10px;
}

.media-body > .media .forum-avatar .rounded-circle {
  height: 38px;
  width: 38px;
}

.mid-icon {
  font-size: 66px;
}

.forum-item {
  margin: 10px 0;
  padding: 10px 0 20px;
  border-bottom: 1px solid #f1f1f1;
}

.views-number {
  font-size: 24px;
  line-height: 18px;
  font-weight: 400;
}

.forum-container,
.forum-post-container {
  padding: 30px !important;
}

.forum-item small {
  color: #999;
}

.forum-item .forum-sub-title {
  color: #999;
  margin-left: 50px;
}

.forum-title {
  margin: 15px 0 15px 0;
}

.forum-info {
  text-align: center;
}

.forum-desc {
  color: #999;
}

.forum-icon {
  float: left;
  width: 30px;
  margin-right: 20px;
  text-align: center;
}

a.forum-item-title {
  color: inherit;
  display: block;
  font-size: 18px;
  font-weight: 600;
}

a.forum-item-title:hover {
  color: inherit;
}

.forum-icon .fa {
  font-size: 30px;
  margin-top: 8px;
  color: #9b9b9b;
}

.forum-item.active .fa {
  color: variables.$navy;
}

.forum-item.active a.forum-item-title {
  color: variables.$navy;
}

@media (max-width: 992px) {
  .forum-info {
    margin: 15px 0 10px 0;

    /* Comment this is you want to show forum info in small devices */
    display: none;
  }

  .forum-desc {
    float: none !important;
  }
}

/* New Timeline style */

.vertical-container {
  /* this class is used to give a max-width to the element it is applied to, and center it horizontally when it reaches that max-width */
  width: 90%;
  max-width: 1170px;
  margin: 0 auto;
}

.vertical-container::after {
  /* clearfix */
  content: '';
  display: table;
  clear: both;
}

#vertical-timeline {
  position: relative;
  padding: 0;
  margin-top: 2em;
  margin-bottom: 2em;
}

#vertical-timeline::before {
  content: '';
  position: absolute;
  top: 0;
  left: 18px;
  height: 100%;
  width: 4px;
  background: #f1f1f1;
}

.vertical-timeline-content .btn {
  float: right;
}

#vertical-timeline.light-timeline:before {
  background: #e7eaec;
}

.dark-timeline .vertical-timeline-content:before {
  border-color: transparent #f5f5f5 transparent transparent;
}

.dark-timeline.center-orientation .vertical-timeline-content:before {
  border-color: transparent transparent transparent #f5f5f5;
}

.dark-timeline
.vertical-timeline-block:nth-child(2n)
.vertical-timeline-content:before,
.dark-timeline.center-orientation
.vertical-timeline-block:nth-child(2n)
.vertical-timeline-content:before {
  border-color: transparent #f5f5f5 transparent transparent;
}

.dark-timeline .vertical-timeline-content,
.dark-timeline.center-orientation .vertical-timeline-content {
  background: #f5f5f5;
}

@media only screen and (min-width: 1170px) {
  #vertical-timeline.center-orientation {
    margin-top: 3em;
    margin-bottom: 3em;
  }

  #vertical-timeline.center-orientation:before {
    left: 50%;
    margin-left: -2px;
  }
}

@media only screen and (max-width: 1170px) {
  .center-orientation.dark-timeline .vertical-timeline-content:before {
    border-color: transparent #f5f5f5 transparent transparent;
  }
}

.vertical-timeline-block {
  position: relative;
  margin: 2em 0;
}

.vertical-timeline-block:after {
  content: '';
  display: table;
  clear: both;
}

.vertical-timeline-block:first-child {
  margin-top: 0;
}

.vertical-timeline-block:last-child {
  margin-bottom: 0;
}

@media only screen and (min-width: 1170px) {
  .center-orientation .vertical-timeline-block {
    margin: 4em 0;
  }

  .center-orientation .vertical-timeline-block:first-child {
    margin-top: 0;
  }

  .center-orientation .vertical-timeline-block:last-child {
    margin-bottom: 0;
  }
}

.vertical-timeline-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 16px;
  border: 3px solid #f1f1f1;
  text-align: center;
}

.vertical-timeline-icon i {
  display: block;
  width: 24px;
  height: 24px;
  position: relative;
  left: 50%;
  top: 50%;
  margin-left: -12px;
  margin-top: -9px;
}

@media only screen and (min-width: 1170px) {
  .center-orientation .vertical-timeline-icon {
    width: 50px;
    height: 50px;
    left: 50%;
    margin-left: -25px;
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    font-size: 19px;
  }

  .center-orientation .vertical-timeline-icon i {
    margin-left: -12px;
    margin-top: -10px;
  }

  .center-orientation .cssanimations .vertical-timeline-icon.is-hidden {
    visibility: hidden;
  }
}

.vertical-timeline-content {
  position: relative;
  margin-left: 60px;
  background: white;
  border-radius: 0.25em;
  padding: 1em;
}

.vertical-timeline-content:after {
  content: '';
  display: table;
  clear: both;
}

.vertical-timeline-content h2 {
  font-weight: 400;
  margin-top: 4px;
}

.vertical-timeline-content p {
  margin: 1em 0;
  line-height: 1.6;
}

.vertical-timeline-content .vertical-date {
  float: left;
  font-weight: 500;
}

.vertical-date small {
  color: variables.$navy;
  font-weight: 400;
}

.vertical-timeline-content::before {
  content: '';
  position: absolute;
  top: 16px;
  right: 100%;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-right: 7px solid white;
}

@media only screen and (min-width: 768px) {
  .vertical-timeline-content h2 {
    font-size: 18px;
  }

  .vertical-timeline-content p {
    font-size: 13px;
  }
}

@media only screen and (min-width: 1170px) {
  .center-orientation .vertical-timeline-content {
    margin-left: 0;
    padding: 1.6em;
    width: 45%;
  }

  .center-orientation .vertical-timeline-content::before {
    top: 24px;
    left: 100%;
    border-color: transparent;
    border-left-color: white;
  }

  .center-orientation .vertical-timeline-content .btn {
    float: left;
  }

  .center-orientation .vertical-timeline-content .vertical-date {
    position: absolute;
    width: 100%;
    left: 122%;
    top: 2px;
    font-size: 14px;
  }

  .center-orientation
  .vertical-timeline-block:nth-child(even)
  .vertical-timeline-content {
    float: right;
  }

  .center-orientation
  .vertical-timeline-block:nth-child(even)
  .vertical-timeline-content::before {
    top: 24px;
    left: auto;
    right: 100%;
    border-color: transparent;
    border-right-color: white;
  }

  .center-orientation
  .vertical-timeline-block:nth-child(even)
  .vertical-timeline-content
  .btn {
    float: right;
  }

  .center-orientation
  .vertical-timeline-block:nth-child(even)
  .vertical-timeline-content
  .vertical-date {
    left: auto;
    right: 122%;
    text-align: right;
  }

  .center-orientation .cssanimations .vertical-timeline-content.is-hidden {
    visibility: hidden;
  }
}

/* Tabs */

.tabs-container {
  .panel-body {
    background: #fff;
    border: 1px solid variables.$border-color;
    border-radius: 2px;
    padding: 20px;
    position: relative;
  }

  .nav-tabs > li.active > a,
  .nav-tabs > li.active > a:hover,
  .nav-tabs > li.active > a:focus {
    border: 1px solid variables.$border-color;
    border-bottom-color: transparent;
    background-color: #fff;
  }

  .nav-tabs > li {
    float: left;
    margin-bottom: -1px;
  }

  .tab-pane .panel-body {
    border-top: none;
  }

  .nav-tabs > li.active > a,
  .nav-tabs > li.active > a:hover,
  .nav-tabs > li.active > a:focus {
    border: 1px solid variables.$border-color;
    border-bottom-color: transparent;
  }

  .nav-tabs {
    border-bottom: 1px solid variables.$border-color;
  }

  .tab-pane .panel-body {
    border-top: none;
  }

  .tabs-left .tab-pane .panel-body,
  .tabs-right .tab-pane .panel-body {
    border-top: 1px solid variables.$border-color;
  }

  .tabs-below > .nav-tabs,
  .tabs-right > .nav-tabs,
  .tabs-left > .nav-tabs {
    border-bottom: 0;
  }

  .tabs-left .panel-body {
    position: static;
  }

  .tabs-left > .nav-tabs,
  .tabs-right > .nav-tabs {
    width: 20%;
  }

  .tabs-left .panel-body {
    width: 80%;
    margin-left: 20%;
  }

  .tabs-right .panel-body {
    width: 80%;
    margin-right: 20%;
  }

  .tab-content > .tab-pane,
  .pill-content > .pill-pane {
    display: none;
  }

  .tab-content > .active,
  .pill-content > .active {
    display: block;
  }

  .tabs-below > .nav-tabs {
    border-top: 1px solid variables.$border-color;
  }

  .tabs-below > .nav-tabs > li {
    margin-top: -1px;
    margin-bottom: 0;
  }

  .tabs-below > .nav-tabs > li > a {
    -webkit-border-radius: 0 0 4px 4px;
    -moz-border-radius: 0 0 4px 4px;
    border-radius: 0 0 4px 4px;
  }

  .tabs-below > .nav-tabs > li > a:hover,
  .tabs-below > .nav-tabs > li > a:focus {
    border-top-color: variables.$border-color;
    border-bottom-color: transparent;
  }

  .tabs-left > .nav-tabs > li,
  .tabs-right > .nav-tabs > li {
    float: none;
  }

  .tabs-left > .nav-tabs > li > a,
  .tabs-right > .nav-tabs > li > a {
    min-width: 124px;
    margin-right: 0;
    margin-bottom: 3px;
  }

  .tabs-left > .nav-tabs {
    float: left;
    margin-right: 19px;
  }

  .tabs-left > .nav-tabs > li > a {
    margin-right: -1px;
    -webkit-border-radius: 4px 0 0 4px;
    -moz-border-radius: 4px 0 0 4px;
    border-radius: 4px 0 0 4px;
  }

  .tabs-left > .nav-tabs a.active,
  .tabs-left > .nav-tabs a.active:hover,
  .tabs-left > .nav-tabs a.active:focus {
    border-color: variables.$border-color transparent variables.$border-color variables.$border-color;
  }

  .tabs-right > .nav-tabs {
    float: right;
    margin-left: 19px;
  }

  .tabs-right > .nav-tabs > li > a {
    margin-left: -1px;
    -webkit-border-radius: 0 4px 4px 0;
    -moz-border-radius: 0 4px 4px 0;
    border-radius: 0 4px 4px 0;
  }

  .tabs-right > .nav-tabs a.active,
  .tabs-right > .nav-tabs a.active:hover,
  .tabs-right > .nav-tabs a.active:focus {
    border-color: variables.$border-color variables.$border-color variables.$border-color transparent;
    z-index: 1;
  }

  .tabs-right > .nav-tabs li {
    z-index: 1;
  }
}

.nav-tabs .nav-link:not(.active):focus,
.nav-tabs .nav-link:not(.active):hover {
  border-color: transparent;
}

@media (max-width: 767px) {
  .tabs-container .nav-tabs > li {
    float: none !important;
  }

  .tabs-container .nav-tabs > li.active > a {
    border-bottom: 1px solid #e7eaec !important;
    margin: 0;
  }
}

/*Slick Carousel */

.slick-prev:before,
.slick-next:before {
  color: variables.$navy !important;
}

/* Payments */

.payment-card {
  background: #ffffff;
  padding: 20px;
  margin-bottom: 25px;
  border: 1px solid variables.$border-color;
}

.payment-icon-big {
  font-size: 60px;
  color: variables.$light-gray;
}

.payments-method.panel-group .panel + .panel {
  margin-top: -1px;
}

.payments-method .panel-heading {
  padding: 15px;
  background-color: variables.$gray;
}

.payments-method .panel-default {
  border: 1px solid variables.$border-color;
}

.payments-method .panel {
  border-radius: 0;
}

.payments-method .panel-heading h5 {
  margin-bottom: 5px;
}

.payments-method .panel-heading i {
  font-size: 26px;
}

/* Select2 custom styles */

.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--multiple {
  border-color: variables.$border-color;
}

.select2-container--default.select2-container--focus .select2-selection--single,
.select2-container--default.select2-container--focus
.select2-selection--multiple {
  border-color: variables.$navy;
}

.select2-container--default
.select2-results__option--highlighted[aria-selected] {
  background-color: variables.$navy;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border-color: variables.$border-color;
}

.select2-dropdown {
  border-color: variables.$border-color;

  input:focus {
    outline: none;
  }
}

.select2-selection {
  outline: none;
}

.ui-select-container.ui-select-bootstrap .ui-select-choices-row.active > a {
  background-color: variables.$navy;
}

/* Tour */

.tour-tour .btn.btn-default {
  background-color: #ffffff;
  border: 1px solid #d2d2d2;
  color: inherit;
}

.tour-step-backdrop {
  z-index: 2101;
}

.tour-backdrop {
  z-index: 2100;
  opacity: 0.7;
}

.popover[class*='tour-'] {
  z-index: 2100;
}

.popover-header {
  margin-top: 0;
}

body.tour-open .animated {
  animation-fill-mode: initial;
}

.tour-tour .btn.btn-secondary {
  background-color: #ffffff;
  border: 1px solid #d2d2d2;
  color: inherit;
}

/* Resizable */

.resizable-panels .ibox {
  clear: none;
  margin: 10px;
  float: left;
  overflow: hidden;
  min-height: 150px;
  min-width: 150px;
}

//.resizable-panels .ibox .ibox-content {
//  height: calc(~'100% - 49px');
//}

.ui-resizable-helper {
  background: rgba(211, 211, 211, 0.4);
}

/* Wizard step fix */
.wizard > .content > .body {
  position: relative;
}

/* PDF js style */

.pdf-toolbar {
  max-width: 600px;
  margin: 0 auto;
}

/* Dropzone */

.dropzone {
  min-height: 140px;
  border: 1px dashed variables.$navy;
  background: white;
  padding: 20px 20px;

  .dz-message {
    font-size: 16px;
  }
}

/* Activity stream */

.stream {
  position: relative;
  padding: 10px 0;

  &:first-child .stream-badge:before {
    top: 10px;
  }

  &:last-child .stream-badge:before {
    height: 30px;
  }

  .stream-badge {
    width: 50px;

    i {
      border: 1px solid variables.$border-color;
      border-radius: 50%;
      padding: 6px;
      color: lighten(variables.$text-color, 10%);
      position: absolute;
      background-color: #ffffff;
      left: 8px;

      &.fa-circle {
        color: lighten(variables.$text-color, 40%);
      }

      &.bg-success {
        color: #ffffff;
        background-color: variables.$blue;
        border-color: variables.$blue;
      }

      &.bg-primary {
        color: #ffffff;
        background-color: variables.$navy;
        border-color: variables.$navy;
      }

      &.bg-warning {
        color: #ffffff;
        background-color: variables.$yellow;
        border-color: variables.$yellow;
      }

      &.bg-info {
        color: #ffffff;
        background-color: variables.$lazur;
        border-color: variables.$lazur;
      }

      &.bg-danger {
        color: #ffffff;
        background-color: variables.$red;
        border-color: variables.$red;
      }
    }

    &:before {
      content: '';
      width: 1px;
      background-color: variables.$border-color;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 20px;
    }
  }

  .stream-info {
    font-size: 12px;
    margin-bottom: 5px;

    img {
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-right: 2px;
      margin-top: -4px;
    }

    .date {
      color: lighten(variables.$text-color, 20%);
      font-size: 80%;
    }
  }

  .stream-panel {
    margin-left: 55px;
  }
}

.stream-small {
  margin: 10px 0;

  .label {
    padding: 2px 6px;
    margin-right: 2px;
  }
}

/* Code */

pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: lighten(variables.$light-gray, 10%);
  border: 1px solid variables.$light-gray;
  border-radius: 2px;
}

code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;
}
