/* Import main Inspinia style */
@use 'assets/styles/imports/variables';

@use 'assets/styles/style';
@use '../node_modules/@fortawesome/fontawesome-pro/css/all.css';

// Fullcalendar mobile/responsive config
// https://github.com/fullcalendar/fullcalendar/issues/4638
@media (max-width: 767px) {
  .fc-toolbar.fc-header-toolbar {
    display: flex;
    flex-direction: column;
    align-items: baseline !important;
  }
  .fc-toolbar.fc-header-toolbar .fc-left {
    order: 3;
  }
  .fc-toolbar.fc-header-toolbar .fc-center {
    order: 1;
  }
  .fc-toolbar.fc-header-toolbar .fc-right {
    order: 2;
  }
  h2 {
    font-size: large;
  }
  .fc-toolbar.fc-header-toolbar .fc-toolbar-title {
    font-size: 18px;
  }
}
.fc button {
  background: variables.$eca-light-gray;
  color: white;
  border: 1px solid;
}

.fc button.active {
  background: variables.$eca-gray !important;
  border: 1px solid white;
  //font-weight: bold;
}
.fc button:hover {
  background: variables.$dark-gray !important;
  color: white;
}

.fc button:disabled {
  background: variables.$light-blue;
  color: variables.$light-gray;
  border: 1px solid white;
}

.fc .fc-list-day-cushion {
  background-color: lightgray;
}

// Avoid datepicker behind modal
bs-datepicker-container {
  z-index: 9999 !important;
}

// Avoid ckeditor behind modal

.ck .ck-balloon-panel {
  z-index: 9999 !important;
}
