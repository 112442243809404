/*
 *
 *   INSPINIA - Responsive Admin Theme
 *   version 2.7.1
 *
*/
// Google Fonts
// Variables, Mixins
@use './imports/variables';
// INSPINIA Theme Elements
@use './imports/typography';
@use './imports/navigation';
@use './imports/top_navigation';
@use './imports/buttons';
@use './imports/badges_labels';
@use './imports/elements';
@use './imports/base';
@use './imports/pages';
@use './imports/spinners';
@use './imports/theme-config';
@use './imports/media';
@use './imports/table';
@use "primeicons/primeicons.css";

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');


// Clear layout on print mode
@media print {
  nav.navbar-static-side {
    display: none;
  }
  body {
    overflow: visible !important;
  }

  #page-wrapper {
    margin: 0;
  }
}

// Fix problem with responsive image on "Announce card"
.ck-content > p >img {
  width: 100%;
}

